// Angular Modules
import {NgModule} from '@angular/core';
// Components
import {CoreModule} from './core/core.module';
import {FeaturesModule} from './features/features.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GeocodeService } from './services/geocode.service';



const MODULES: any[] = [
  CoreModule,
  FeaturesModule,
  AppRoutingModule,
  FontAwesomeModule
];


const DECLARATIONS: any[] = [
  AppComponent
];


@NgModule({
  imports: MODULES,
  declarations: DECLARATIONS,
  bootstrap: [AppComponent],
  providers: [GeocodeService],
})
export class AppModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    library.add(fas, far);
  }
}
