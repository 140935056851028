import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Location } from '../models/location.model';

declare var google: any;

@Injectable()
export class GeocodeService {
  constructor() {}

  geocodeAddress(location: string): Observable<Location> {
    console.log('Start geocoding!');

    return new Observable(observer => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({'address': location}, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
            console.log('Geocoding complete!');
            observer.next({
            lat: results[0].geometry.location.lat(), 
            lng: results[0].geometry.location.lng()
            });
        } else {
            console.log('Error - ', results, ' & Status - ', status);
            observer.next({ lat: 0, lng: 0 });
        }
        observer.complete();
        });
    })
  }
  
}