import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Customer, OrderLine, OrderDetail, Order } from '@app/models/models';
import { Paginator, Menu, ContextMenu, DataTable, Message, LazyLoadEvent } from 'primeng/primeng';
import { OrderLineDetailsDialogComponent } from '@app/features/back-office/orders/orders-line/order-line-details-dialog/order-line-details-dialog.component';
import { OrdersDetailsDialogComponent } from '@app/features/back-office/orders/orders-details-dialog/orders-details-dialog.component';
import { OrderFilters } from '@app/models/orderFilters';
import { OrderLineService } from '@app/services/orderLine.service';
import { OrderDetailService } from '@app/services/orderDetail.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AppConfig } from '@app/models/app-config.model';
import { PeriodStatusService } from '@app/services/periodStatus.service';
import { IconService } from '@app/services/icon.service';
import { Session } from '@app/app.session';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/services/authentication.service';
import { Observable } from 'rxjs';
import { NewClaim } from '@app/models/newClaim';
import { NewClaimLine } from '@app/models/newClaimLine';
import { ClaimPicture } from '@app/models/claimPicture';
import { ClaimService } from '@app/services/claim.service';
import { PrintErrorDialog } from '@app/models/printErrorDialog';

@Component({
  selector: 'app-table-orders',
  templateUrl: './table-orders.component.html',
  styleUrls: ['./table-orders.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  providers: [DatePipe]
})
export class TableOrdersComponent implements OnInit {
  // INPUT
  @Input() public selectedCustomerIds: number[];

  @Input() public customerChecked: Boolean;

  @Input() public loading: Boolean;

  @Input() public filterOrders: OrderFilters;

  @Input() public clients: Customer[];
  // Résultat Recherche
  @Input() public searchOrdersLines: OrderLine[];

  @Input() public searchOrderDetails: OrderDetail[] = [];

  @Input() public searchLaunchedOnce: Boolean;

  @Input() public totalRecords: number;

  @Input() public printingMode = false;

  @Input() public barcode?: Boolean = false;

  @Input() public checkedOrderLines: String[];

  @Input() public checkedOrderIdLines: String[];

  // ViewChild

  @ViewChild('paginator') paginator: Paginator;

  @ViewChild('menu') menu: Menu;

  @ViewChild('cm') contextMenu: ContextMenu;

  @ViewChild('ordersDetailsDialogComponent') ordersDetailsDialogComponent: OrdersDetailsDialogComponent;

  @ViewChild('ordersLineDetailsDialogComponent') ordersLineDetailsDialogComponent: OrderLineDetailsDialogComponent;

  @ViewChild('searchOrdersLinesDetailsDataTable') public searchOrdersLinesDetailsDataTable: DataTable;

  @ViewChild('searchOrdersLinesDataTable') public searchOrdersLinesDataTable: DataTable;

  // Output

  @Output() emitPass: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Output() tableIsLoad: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Output() printingErrorDialog = new EventEmitter<PrintErrorDialog>();

  public msgs: Message[] = [];

  // Recherche
  public pageNumber = 1;

  public pageSize = 10;

  public selectedOrderLineDetail: OrderLine;

  public selectedOrderDetail: OrderDetail;

  public searchOrdersLinesDetailsDataTableCols: any[];

  public expandAll: Boolean = false;

  public icons = [];

  columns: any[];

  public get newSav(): NewClaim {
    return this.claimService.newSav;
  }

  public set newSav(value: NewClaim) {
    this.claimService.newSav = value;
  }

  constructor(private orderLineService: OrderLineService,
    private orderDetailService: OrderDetailService,
    private translateService: TranslateService,
    private session: Session,
    public appConfig: AppConfig,
    public periodStatusService: PeriodStatusService,
    private iconService: IconService,
    public datepipe: DatePipe,
    private route: Router,
    public authenticationService: AuthenticationService,
    private claimService: ClaimService) {
    this.translateService.onLangChange.subscribe(this.onLangChange.bind(this));

    if (this.session && this.session.connectedUser && this.session.connectedUser.languageCode) {
      this.translateService.use(this.session.connectedUser.languageCode);
    }
  }

  onLangChange(langE: LangChangeEvent) {
    this.translate();
  }

  translate() {
    this.columns =
      [
        { field: 'orderYear', header: this.translateService.instant('ORDERS_GRID_YEAR') },
        { field: 'orderSequence', header: this.translateService.instant('ORDERS_GRID_ORDER') },
        { field: 'reference', header: this.translateService.instant('ORDERS_GRID_REFERENCE') },
        { field: 'orderLinesCount', header: this.translateService.instant('ORDERS_GRID_COUNT') },
        { field: 'orderQuantity', header: this.translateService.instant('ORDERS_GRID_INPUT_QUANTITY') },
        { field: 'deliveryDate', header: this.translateService.instant('OL_DELIVERY_DATE') }
      ];
  }

  ngOnInit() {
    this.searchOrdersLines = [];
    this.newSav = {} as NewClaim;
    this.newSav.claimLines = [] as NewClaimLine[];
    this.iconService.iconGetOrderIcons().subscribe(
      result => {
        this.icons = result;
      }, (err) => {
        console.log(err);
      });

    this.translate();
  }

  displayErrorMessage(errorMessage: string) {
    this.msgs.push(
      <Message>{
        severity: 'error',
        summary: this.translateService.instant('CMN_ERREUR'),
        detail: errorMessage
      }
    );
  }

  searchPage(event: any) {
    this.filterOrders.customers = undefined;

    if (event && event.rows) {
      this.pageSize = event.rows;
    }

    if (this.searchLaunchedOnce) {
      this.loading = true;

      this.filterOrders.pageSize = this.pageSize;
      this.filterOrders.deliveryPeriod = this.filterOrders.deliveryPeriod ? this.filterOrders.deliveryPeriod : undefined;
      this.filterOrders.year = this.filterOrders.year ? this.filterOrders.year : undefined;
      this.filterOrders.clientId = this.session.connectedUser.clientId;
      this.filterOrders.width = this.filterOrders.width ? this.filterOrders.width : undefined;
      this.filterOrders.agres = this.filterOrders.agres ? this.filterOrders.agres : undefined;
      this.filterOrders.article = this.filterOrders.article ? this.filterOrders.article : undefined;
      this.filterOrders.barcode = this.filterOrders.barcode ? this.filterOrders.barcode : undefined;
      this.filterOrders.brace = this.filterOrders.brace ? this.filterOrders.brace : undefined;
      this.filterOrders.extra = this.filterOrders.extra ? this.filterOrders.extra : undefined;
      this.filterOrders.fromDate = this.filterOrders.fromDate ? this.filterOrders.fromDate : null;
      this.filterOrders.glassID = this.filterOrders.glassID ? this.filterOrders.glassID : undefined;
      this.filterOrders.height = this.filterOrders.height ? this.filterOrders.height : undefined;
      this.filterOrders.latelyDelivered = this.filterOrders.latelyDelivered ? this.filterOrders.latelyDelivered : undefined;
      this.filterOrders.missing = this.filterOrders.missing ? this.filterOrders.missing : undefined;
      this.filterOrders.notDelivered = this.filterOrders.notDelivered ? this.filterOrders.notDelivered : undefined;
      this.filterOrders.notDeliveredToday = this.filterOrders.notDeliveredToday ? this.filterOrders.notDeliveredToday : undefined;
      this.filterOrders.references = this.filterOrders.references ? this.filterOrders.references : undefined;
      this.filterOrders.toDate = this.filterOrders.toDate ? this.filterOrders.toDate : undefined;
      this.filterOrders.pageNumber = this.filterOrders.pageNumber = event.page + 1;

      if (this.selectedCustomerIds.length > 0 && !this.customerChecked) {

        this.selectedCustomerIds.forEach((c, i) => {
          const selected = this.selectedCustomerIds[i];

          if (selected) {
            if (this.filterOrders.customers === undefined) {
              this.filterOrders.customers = c.toString();
            } else {
              this.filterOrders.customers += ',' + c;
            }
          }
        });
      } else if (this.customerChecked) {
        this.clients.forEach((c, i) => {
          const client = this.clients[i];
          if (this.filterOrders.customers === undefined) {

            this.filterOrders.customers = client.id.toString();
          } else {
            this.filterOrders.customers += ',' + client.id;
          }
        });
      } else {
        this.filterOrders.customers = this.session.connectedUser.clientId.toString();
      }


      if (this.filterOrders) {
        this.orderDetailService.orderDetailGetAll(this.filterOrders.pageNumber,
          this.filterOrders.clientId,
          this.filterOrders.pageSize,
          true,
          this.filterOrders.deliveryPeriod,
          this.filterOrders.references,
          this.filterOrders.customers,
          this.filterOrders.year,
          this.filterOrders.agres,
          this.filterOrders.fromDate ?
            this.datepipe.transform(this.filterOrders.fromDate, 'dd/MM/yyyy').toString()
            : undefined,
          this.filterOrders.toDate ?
            this.datepipe.transform(this.filterOrders.toDate, 'dd/MM/yyyy').toString()
            : undefined,
          this.filterOrders.sort,
          this.filterOrders.sortBy,
          this.filterOrders.width,
          this.filterOrders.height,
          this.filterOrders.latelyDelivered,
          this.filterOrders.notDelivered,
          this.filterOrders.missing,
          this.filterOrders.barcode,
          this.filterOrders.orderID,
          this.filterOrders.glassID,
          undefined,
          undefined).subscribe(
            r => {
              this.searchOrderDetails = r.entities.map(sod => {
                sod.orderLine = [];
                this.collapseAllRows(this.searchOrdersLinesDataTable);
                return sod;
              });

              this.loading = false;
              this.totalRecords = r.total;
              this.tableIsLoad.emit();
            }, (err) => {
              this.loading = false;
              console.log(err);
              const errorMessage: PrintErrorDialog = {
                    visible: true,
                    title:"Erreur",
                    message: this.translateService.instant('TOO_MUCH_RESULTS'),
                }
                    this.printingErrorDialog.emit(errorMessage);
                }
          );
      }
    }
  }

  public getDeliveryDate(ol: OrderLine): string {
    let result = '';
    if (ol && ol.deliveryDate) {
      ol.deliveryDate.forEach((d, i, a) => {
        this.datepipe.transform(d, 'dd/MM/yyyy') === '01/01/1900' ? result += '' : result += this.datepipe.transform(d, 'dd/MM/yyyy');
        if (i < a.length - 1) {
          result += ' ';
        } });
    }

    return result;
  }

  public getIconKey(status): string {
    if (typeof status === 'number') {
      status = status.toString();
    }
    const icon = this.icons.find(i => i.key.split('_')[1] === status);
    return icon ? icon.key : null;
  }

  public getIconValue(status: string | number): string {
    if (typeof status === 'number') {
      status = status.toString();
    }
    const icon = this.icons.find(i => i.key.split('_')[1] === status);
    return icon ? icon.value : null;
  }

  public detailsShow(od: OrderDetail) {
    this.selectedOrderDetail = od;
    this.ordersDetailsDialogComponent.show(od);
  }

  public detailsLineShow(ol: OrderLine) {
    this.selectedOrderLineDetail = ol;
    this.ordersLineDetailsDialogComponent.show();
  }

  public hasDateInpast(od: OrderDetail): boolean {
    let hasDateInpast = false;
    const currentDate = new Date();
    currentDate.setHours(0,0,0,0);
    if(od.multipleDates && od.multipleDates.length > 0) {
      od.multipleDates.forEach(d => {
        if (d.trim().length > 0) {
          const arraySplittedDate = d.split("/");
          var splittedDate = new Date(+arraySplittedDate[2], +arraySplittedDate[1] - 1, +arraySplittedDate[0]);
        
          if (splittedDate < currentDate) {
            hasDateInpast = true;
          }
        }        
      });
    }
    
    return hasDateInpast;
  }

  public loadDetails(od: OrderDetail, i: number) {
    if (this.selectedCustomerIds.length > 0 && !this.customerChecked) {

      this.selectedCustomerIds.forEach((c, i) => {
        const selected = this.selectedCustomerIds[i];

        if (selected) {
          if (this.filterOrders.customers === undefined) {
            this.filterOrders.customers = c.toString();
          } else {
            this.filterOrders.customers += ',' + c;
          }
        }
      });
    } else if (this.customerChecked) {
      this.clients.forEach((c, i) => {
        const client = this.clients[i];
        if (this.filterOrders.customers === undefined) {

          this.filterOrders.customers = client.id.toString();
        } else {
          this.filterOrders.customers += ',' + client.id;
        }
      });
    } else {
      this.filterOrders.customers = this.session.connectedUser.clientId.toString();
    }

    this.loading = true;
    this.orderLineService.orderLineGetAll(
      this.session.connectedUser ? this.session.connectedUser.clientId : undefined,
      this.filterOrders.customers,
      od.orderYear ? od.orderYear : undefined,
      od.orderSequence ? od.orderSequence.toString() : undefined,
      this.filterOrders.references,
      this.filterOrders.barcode,
      this.filterOrders.width ? this.filterOrders.width.toString() : undefined,
      this.filterOrders.height ? this.filterOrders.height.toString() : undefined,
      this.filterOrders.agres,
      undefined,
      this.filterOrders.missing ? 'true' : 'false',
      this.filterOrders.notDelivered ? 'true' : 'false',
      this.filterOrders.latelyDelivered ? 'true' : 'false',
      this.filterOrders.deliveryPeriod,
      this.filterOrders.fromDate ? this.datepipe.transform(this.filterOrders.fromDate, 'dd/MM/yyyy').toString() : undefined,
      this.filterOrders.toDate ? this.datepipe.transform(this.filterOrders.toDate, 'dd/MM/yyyy').toString() : undefined,
      this.filterOrders.glassID,
      this.filterOrders.orderLineID
    ).subscribe(
      r => {
        this.searchOrdersLines = r;
        this.searchOrderDetails[i].orderLine = this.searchOrdersLines;
        this.loading = false;
      }, (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  public expandAllRows(searchOrdersLinesDataTable: any) {
    if (this.searchOrderDetails) {
      this.searchOrderDetails.forEach(
        (sod, i) => {
          this.loading = true;
          this.orderLineService.orderLineGetAll(this.session.connectedUser ? this.session.connectedUser.clientId : undefined,
            undefined,
            sod.orderYear ? sod.orderYear : undefined,
            sod.orderSequence ? sod.orderSequence.toString() : undefined,
            undefined,
            this.filterOrders.barcode)
            .subscribe(
              r => {
                this.searchOrdersLines = r;
                this.searchOrderDetails[i].orderLine = this.searchOrdersLines;
                this.loading = false;
              }, (err) => {
                this.loading = false;
                console.log(err);
              }
            );
          this.expandAll = true;
          searchOrdersLinesDataTable.expandedRowKeys[sod.orderSequence] = 1;
        }
      );
    }
  }

  public collapseAllRows(searchOrdersLinesDataTable: any) {
    this.expandAll = false;
    searchOrdersLinesDataTable.expandedRowKeys = {};
  }

  public checkOrderChanged(event, od, i) {
      if (this.selectedCustomerIds.length > 0 && !this.customerChecked) {

        this.selectedCustomerIds.forEach((c, i) => {
          const selected = this.selectedCustomerIds[i];

          if (selected) {
            if (this.filterOrders.customers === undefined) {
              this.filterOrders.customers = c.toString();
            } else {
              this.filterOrders.customers += ',' + c;
            }
          }
        });
      } else if (this.customerChecked) {
        this.clients.forEach((c, i) => {
          const client = this.clients[i];
          if (this.filterOrders.customers === undefined) {

            this.filterOrders.customers = client.id.toString();
          } else {
            this.filterOrders.customers += ',' + client.id;
          }
        });
      } else {
        this.filterOrders.customers = this.session.connectedUser.clientId.toString();
      }
      this.loading = true;
      this.orderLineService.orderLineGetAll(
        this.session.connectedUser ? this.session.connectedUser.clientId : undefined,
        this.filterOrders.customers,
        od.orderYear ? od.orderYear : undefined,
        od.orderSequence ? od.orderSequence.toString() : undefined,
        this.filterOrders.references,
        this.filterOrders.barcode,
        this.filterOrders.width ? this.filterOrders.width.toString() : undefined,
        this.filterOrders.height ? this.filterOrders.height.toString() : undefined,
        this.filterOrders.agres,
        undefined,
        this.filterOrders.missing ? 'true' : 'false',
        this.filterOrders.notDelivered ? 'true' : 'false',
        this.filterOrders.latelyDelivered ? 'true' : 'false',
        this.filterOrders.deliveryPeriod,
        this.filterOrders.fromDate ? this.datepipe.transform(this.filterOrders.fromDate, 'dd/MM/yyyy').toString() : undefined,
        this.filterOrders.toDate ? this.datepipe.transform(this.filterOrders.toDate, 'dd/MM/yyyy').toString() : undefined,
        this.filterOrders.glassID,
        this.filterOrders.orderLineID
      ).subscribe(
        r => {
          let canCreateComplaitForAllRow = true;
          this.searchOrdersLines = r;
          this.searchOrderDetails[i].orderLine = this.searchOrdersLines;
          this.searchOrdersLines.forEach(ol => {
            if (this.authenticationService.canCreateComplaint() && this.getDeliveryDate(ol) !== undefined  && ol.deliveredQuantity !== 0) {
              if (event) {
                this.checkedOrderIdLines.push(ol.id);
              } else {
                this.checkedOrderIdLines = this.checkedOrderIdLines.filter(k => k !== ol.id);
              }
              this.checkOrderLineChanged(event, ol, od);
            } else {
              canCreateComplaitForAllRow = false;
            }
          });
          this.loading = false;
          if (!canCreateComplaitForAllRow) {
            this.checkedOrderLines = this.checkedOrderLines.filter(f => f !== od.orderId);
          }
          this.emitPass.emit([this.checkedOrderLines, this.checkedOrderIdLines]);

        }, (err) => {
          this.loading = false;
          console.log(err);
        }
      );
  }

  public checkOrderLineChanged(event, orderLine: OrderLine, orderDetail: OrderDetail) {
    let allSelected = true;
    if (!this.newSav.claimLines.some(p => p.orderYear === orderLine.orderYear &&
      p.orderId === orderLine.orderSequence && p.orderLineId === orderLine.sequence)) {
      if (event) {
        this.newSav.claimLines.push({
          deliveryDate: orderLine.deliveryDate[0],
          deliveryRackId: orderLine.rackID !== undefined ? +orderLine.rackID.split(' ')[0] : '',
          orderGlassId: orderLine.glassID,
          orderId: orderLine.orderSequence,
          orderLineId: orderLine.sequence,
          orderYear: orderLine.orderYear,
          orderLineIdStr: orderLine.id,
          pictures: [],
          orderDetail: orderDetail
        } as NewClaimLine);
      }
    } else {
      if (!event) {
          const orderLineStr = orderLine.id;
          this.newSav.claimLines = this.newSav.claimLines.filter(p => p.orderLineIdStr !== orderLineStr);

          this.checkedOrderLines = this.checkedOrderLines.filter(p => p !== orderDetail.orderId);
      }
    }

    this.emitPass.emit([this.checkedOrderLines, this.checkedOrderIdLines]);
  }

  customSort(event: LazyLoadEvent) {
    if (!event.sortField || !this.filterOrders) { return; }
    if (event.sortOrder === 1) {
      this.filterOrders.sortBy = 'asc';
    } else {
      this.filterOrders.sortBy = 'desc';
    }

    this.filterOrders.sort = event.sortField;
    this.searchPage({ page: 0 });
  }
}
