import { DeliveryRackService } from "./../../../services/deliveryRack.service";
import { CountryService } from "./../../../services/country.service";
import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { ReleaseDeliveryRack } from "@app/models/models";
import { DeliveryRack } from "@app/models/models";
import { UpdateDeliveryRackLocation } from "@app/models/updateDeliveryRackLocation";
import { GeocodeService } from "../../../services/geocode.service";
import { Location } from "../../../models/location.model";
import { Country } from "@app/models/country";
import { SelectItem } from "primeng/primeng";
import { TranslateService } from '@ngx-translate/core';
import { empty } from "rxjs";

@Component({
  selector: "app-delivery-racks-release-dialog",
  templateUrl: "./delivery-racks-release-dialog.component.html",
  styleUrls: ["./delivery-racks-release-dialog.component.scss"],
})
export class DeliveryRacksReleaseDialogComponent implements OnInit {
  @Output() rackReleased: EventEmitter<any> = new EventEmitter();
  dialogVisible = false;
  parameters: ReleaseDeliveryRack;
  address: string = "";
  customerID: number = 0;
  countries: Country[] = [];
  countryItems: SelectItem[];
  errorAddress: string = "";
  @Input() public rightAddress: boolean = true;
  @Input() public takeAddress: string;
  @Input() public takeAddressComment: string = "";
  @Input() public takeAddressStreetNumber: string = "";
  @Input() public takeAddressZipCode: string = "";
  @Input() public takeAddressCity: string = "";
  @Input() public takeAddressCountry: string = "";
  location: Location;

  @Output() releaseRackEvent = new EventEmitter<boolean>();

  eReleaseRack(value: boolean) {
    this.releaseRackEvent.emit(value);
  }

  constructor(
    private deliveryRackservice: DeliveryRackService,
    private geocodeService: GeocodeService,
    private countryService: CountryService,
	private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.parameters = {} as ReleaseDeliveryRack;
  }

  show(deliveryRack: DeliveryRack) {
    this.parameters.deliveryRackID = deliveryRack.id;
    this.parameters.toThrow = false;
    this.parameters.empty = true;
	this.customerID = deliveryRack.customerId;
    this.address = deliveryRack.address;

	this.countryService.countryGetAllCountriesAsync('fr').subscribe(
		(res) => {
			this.countryItems = [];
			this.countryItems = res.map(country => <SelectItem>{
				label: country.countryName,
				value: country.countryId
			});
		},
		(error) => {
			this.countries = [];
			console.log('error');
			console.log(error);
		}
	)

    this.dialogVisible = true;
  }

//   displayTruc() {
//     console.log("11111");
//     console.log(this.rightAddress);
//     console.log(this.takeAddressStreetNumber);
//     console.log(this.takeAddressZipCode);
//     console.log(this.takeAddressCity);
//     console.log(this.takeAddressComment);
//     console.log(this.takeAddressCountry);

//     const address =
//       this.takeAddressStreetNumber +
//       " " +
//       this.takeAddressZipCode +
//       " " +
//       this.takeAddressCity;

//     this.geocodeService
//       .geocodeAddress(address)
//       .subscribe((location: Location) => {
//         console.log(location);
//       });
//   }

  releaseRack() {
    let t: UpdateDeliveryRackLocation = {};

    if (!this.rightAddress) {
        if(this.takeAddressStreetNumber == "" || this.takeAddressZipCode == "" || this.takeAddressCity == ""){
            this.errorAddress = this.translateService.instant('FILL_ALL_FIELDS');
        }
        else{
            const address = this.takeAddressStreetNumber + " " + this.takeAddressZipCode + " " + this.takeAddressCity;
    
            this.geocodeService
            .geocodeAddress(address)
            .subscribe((location: Location) => {
                if(location.lat === 0 && location.lng === 0){
                    this.errorAddress = this.translateService.instant('INVALID_ADDRESS');
                }
                else{
                    t.latitude = location.lat;
                    t.longitude = location.lng;
                    t.addressLine1 = this.takeAddressStreetNumber;
                    t.city = this.takeAddressCity;
                    t.postalCode = this.takeAddressZipCode;
                    t.customerId = this.customerID;
                    t.countryCode = this.takeAddressCountry;
                    this.parameters.comment = this.takeAddressComment;
                    this.errorAddress = "";
                    this.eReleaseRack(true);
                    this.dialogVisible = false;
                    this.deliveryRackservice.updateDeliveryRackAddress(this.parameters.deliveryRackID, t).subscribe(
                        (res) => {
                            console.log(res);
                        },
                        (error) => {
                            // TODO: afficher un message d'erreur
                            console.log(error);
                        }
                    );
                    this.deliveryRackservice.deliveryRackRelease(this.parameters).subscribe(
                        () => {
                            this.rackReleased.emit();
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
                }
            });
        }
	}
	else{
		this.deliveryRackservice.deliveryRackRelease(this.parameters).subscribe(
			() => {
				this.rackReleased.emit();
				this.dialogVisible = false;
			},
			(err) => {
				this.dialogVisible = false;
				console.log(err);
			}
		);
	}
  }
}
