/**
 * SynerWS.WebApi v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '@app/core/encoder';

import { Observable } from 'rxjs/Observable';

import { ColisageHeader } from '@app/models/colisageHeader';
import { ColisageLine } from '@app/models/colisageLine';

import { BASE_PATH, COLLECTION_FORMATS } from '@app/core/variables';
import { Configuration } from '@app/core/configuration';

import { AppConfig } from '@app/models/app-config.model';

@Injectable({ providedIn: 'root' })
export class ColisageService {

  protected basePath: string = this.appConfig.configServer.boServerPath;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration,
    public appConfig: AppConfig) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Retrieves the colisages.
   *
   * @param page The page index.
   * @param clientId The client ID.
   * @param pageSize The page size (20 by default).
   * @param count count the number of rows.
   * @param period The delivery period.
   * @param customers Array of customers.
   * @param rackID
   * @param orderYear
   * @param sort Column to sort.
   * @param sortBy Sort by.
   * @param orderID Order ID
   * @param BL BL number
   * @param bLYear
   * @param orderRef Order refrence
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public colisageGetAll(
    page: number, clientId?: number, pageSize?: number, count?: boolean, period?: number, customers?: string, rackID?: string,
    orderYear?: string, sort?: string, sortBy?: string, orderID?: string, BL?: string, bLYear?: string, orderRef?: string, observe?: 'body',
    reportProgress?: boolean): Observable<Array<ColisageHeader>>;
  public colisageGetAll(
    page: number, clientId?: number, pageSize?: number, count?: boolean, period?: number, customers?: string, rackID?: string,
    orderYear?: string, sort?: string, sortBy?: string, orderID?: string, BL?: string, bLYear?: string, orderRef?: string, observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<Array<ColisageHeader>>>;
  public colisageGetAll(
    page: number, clientId?: number, pageSize?: number, count?: boolean, period?: number, customers?: string, rackID?: string,
    orderYear?: string, sort?: string, sortBy?: string, orderID?: string, BL?: string, bLYear?: string, orderRef?: string, observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<Array<ColisageHeader>>>;
  public colisageGetAll(
    page: number, clientId?: number, pageSize?: number, count?: boolean, period?: number, customers?: string, rackID?: string,
    orderYear?: string, sort?: string, sortBy?: string, orderID?: string, BL?: string, bLYear?: string, orderRef?: string, observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (clientId !== undefined) {
      queryParameters = queryParameters.set('clientId', <any>clientId);
    }
    if (pageSize !== undefined) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }
    if (count !== undefined) {
      queryParameters = queryParameters.set('count', <any>count);
    }
    if (period !== undefined) {
      queryParameters = queryParameters.set('period', <any>period);
    }
    if (customers !== undefined) {
      queryParameters = queryParameters.set('customers', <any>customers);
    }
    if (rackID !== undefined) {
      queryParameters = queryParameters.set('rackID', <any>rackID);
    }
    if (orderYear !== undefined) {
      queryParameters = queryParameters.set('orderYear', <any>orderYear);
    }
    if (sort !== undefined) {
      queryParameters = queryParameters.set('sort', <any>sort);
    }
    if (sortBy !== undefined) {
      queryParameters = queryParameters.set('sortBy', <any>sortBy);
    }
    if (orderID !== undefined) {
      queryParameters = queryParameters.set('orderID', <any>orderID);
    }
    if (BL !== undefined) {
      queryParameters = queryParameters.set('BL', <any>BL);
    }
    if (bLYear !== undefined) {
      queryParameters = queryParameters.set('BLYear', <any>bLYear);
    }
    //New
    if(orderRef !== undefined){
        queryParameters = queryParameters.set('orderRef', <any>orderRef);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    console.log('queryParameters');
    console.log(queryParameters);

    return this.httpClient.get<Array<ColisageHeader>>(`${this.basePath}/api/v1/colisages/${encodeURIComponent(String(page))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves the colisage lines.
   *
   * @param clientId The client ID.
   * @param page The page index.
   * @param pageSize The page size (20 by default).
   * @param count count the number of rows.
   * @param period The delivery period.
   * @param customers Array of customers.
   * @param rackID
   * @param deliveryID
   * @param deliveryYear
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public colisageGetAllLines(
    clientId?: number, page?: number, pageSize?: number, count?: boolean, period?: number, customers?: string, rackID?: string,
    deliveryID?: string, deliveryYear?: string, observe?: 'body', reportProgress?: boolean, reference?:string): Observable<Array<ColisageLine>>;
  public colisageGetAllLines(
    clientId?: number, page?: number, pageSize?: number, count?: boolean, period?: number, customers?: string, rackID?: string,
    deliveryID?: string, deliveryYear?: string, observe?: 'response', reportProgress?: boolean, reference?:string):
    Observable<HttpResponse<Array<ColisageLine>>>;
  public colisageGetAllLines(
    clientId?: number, page?: number, pageSize?: number, count?: boolean, period?: number, customers?: string, rackID?: string,
    deliveryID?: string, deliveryYear?: string, observe?: 'events', reportProgress?: boolean, reference?:string): Observable<HttpEvent<Array<ColisageLine>>>;
  public colisageGetAllLines(
    clientId?: number, page?: number, pageSize?: number, count?: boolean, period?: number, customers?: string, rackID?: string,
    deliveryID?: string, deliveryYear?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (clientId !== undefined) {
      queryParameters = queryParameters.set('clientId', <any>clientId);
    }
    if (page !== undefined) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }
    if (count !== undefined) {
      queryParameters = queryParameters.set('count', <any>count);
    }
    if (period !== undefined) {
      queryParameters = queryParameters.set('period', <any>period);
    }
    if (customers !== undefined) {
      queryParameters = queryParameters.set('customers', <any>customers);
    }
    if (rackID !== undefined) {
      queryParameters = queryParameters.set('rackID', <any>rackID);
    }
    if (deliveryID !== undefined) {
      queryParameters = queryParameters.set('deliveryID', <any>deliveryID);
    }
    if (deliveryYear !== undefined) {
      queryParameters = queryParameters.set('deliveryYear', <any>deliveryYear);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    console.log('queryParameters');
    console.log(queryParameters);

    return this.httpClient.get<Array<ColisageLine>>(`${this.basePath}/api/v1/colisages/lines`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves the Rack headers with lines.
   *
   * @param rack The Rack ID.
   * @param clientId The client ID.
   * @param customers Array of customers.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public colisageGetRack(
    rack: string, clientId?: number, customers?: string, observe?: 'body', reportProgress?: boolean, reference?:string):
    Observable<Array<ColisageLine>>;
  public colisageGetRack(
    rack: string, clientId?: number, customers?: string, observe?: 'response', reportProgress?: boolean, reference?:string):
    Observable<HttpResponse<Array<ColisageLine>>>;
  public colisageGetRack(
    rack: string, clientId?: number, customers?: string, observe?: 'events', reportProgress?: boolean, reference?:string):
    Observable<HttpEvent<Array<ColisageLine>>>;
  public colisageGetRack(
    rack: string, clientId?: number, customers?: string, observe: any = 'body', reportProgress: boolean = false, reference?:string):
    Observable<any> {
    if (rack === null || rack === undefined) {
      throw new Error('Required parameter rack was null or undefined when calling colisageGetRack.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (rack !== undefined) {
      queryParameters = queryParameters.set('rack', <any>rack);
    }
    if (clientId !== undefined) {
      queryParameters = queryParameters.set('clientId', <any>clientId);
    }
    if (customers !== undefined) {
      queryParameters = queryParameters.set('customers', <any>customers);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Array<ColisageLine>>(`${this.basePath}/api/v1/colisages/rack`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
