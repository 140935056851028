import { LazyLoadEvent } from 'primeng/primeng';
import { Table } from 'primeng/table';
import { Customer } from '@app/models/models';
import { PackagingFilters } from './../../../models/filters/packagingFilters';
import { ColisageService } from './../../../services/colisage.service';
import { ColisageHeader } from '@app/models/colisageHeader';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Session } from '@app/app.session';

@Component({
  selector: 'app-packaging-table',
  templateUrl: './packaging-table.component.html',
  styleUrls: ['./packaging-table.component.scss']
})
export class PackagingTableComponent implements OnInit {

  @Input() loading = false;
  @Input() packages: ColisageHeader[];
  @Input() filters: PackagingFilters;
  @Input() totalRecords: number;
  @Input() searchLaunchedOnce: boolean;
  @Input() selectedCustomerIds: number[];
  @Input() customerChecked: boolean;
  @Input() customers: Customer[];
  @Input() printingMode = false;

  @ViewChild('packagesDataTable') packagesDataTable: Table;

  public pageNumber = 1;

  public pageSize = 10;

  public packagesTabColumns: any[];
  public packagesLinesTabColumns: any[];

  constructor(private translateService: TranslateService,
              private session: Session,
              private packagingService: ColisageService) {
                this.translateService.onLangChange.subscribe(this.onLangChange.bind(this));

                if (this.session && this.session.connectedUser && this.session.connectedUser.languageCode) {
                  this.translateService.use(this.session.connectedUser.languageCode);
                }
              }

  onLangChange(langE: LangChangeEvent) {
    this.translate();
  }

  translate() {
    this.packagesTabColumns = [
      { field: 'rackID', header: this.translateService.instant('COLISAGES_GRID_RACK') },
      { field: 'deliveryDate', header: this.translateService.instant('COLISAGES_GRID_DELIVERY_DATE') },
      { field: 'customerName', header: this.translateService.instant('COLISAGES_GRID_CLIENT') },
      { field: 'qty', header: this.translateService.instant('COLISAGES_GRID_QUANTITY') },
      { field: 'surface', header: this.translateService.instant('COLISAGES_GRID_SURFACE') },
      { field: 'weight', header: this.translateService.instant('COLISAGES_GRID_WEIGHT') },
    ];

    this.packagesLinesTabColumns = [
      { field: 'orderID', header: this.translateService.instant('COLISAGE_LINE_ORDER_ID') },
      { field: 'width', header: this.translateService.instant('COLISAGE_LINE_WIDTH') },
      { field: 'height', header: this.translateService.instant('COLISAGE_LINE_HEIGHT') },
      { field: 'artDesc', header: this.translateService.instant('COLISAGE_LINE_COMPOSITION') },
      { field: 'orderRef', header: this.translateService.instant('ORDERS_GRID_REFERENCE') },
      { field: 'refChassi', header: this.translateService.instant('COLISAGE_LINE_REF') },
      { field: 'pile', header: this.translateService.instant('COLISAGE_LINE_PILE') },
    ];
  }

  ngOnInit() {
    this.translate();
  }

  loadDetails(colisage: ColisageHeader) {
    this.loading = true;
    this.packagingService.colisageGetAllLines(
      this.session.connectedUser ? this.session.connectedUser.clientId : undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      this.filters.customers,
      colisage.rackID.toString(),
      colisage.deliveryID.toString(),
      colisage.deliveryYear.toString(),
      undefined,
      undefined).subscribe(detail => {
        colisage.colisageLines = detail;
        this.loading = false;
      }, () => {
        this.loading = false;
      });
  }

  printLine(colisage: ColisageHeader) {
    let url = '';
    this.setFilters();
    Object.keys(this.filters).forEach(prop => {
      if (this.filters[prop] !== null && this.filters[prop] !== undefined) {
        url += '&' + prop + '=' + this.filters[prop];
      }
    });
    url += '&rackID=' + colisage.rackID;
    url += '&deliveryID=' + colisage.deliveryID;
    window.open('#/printing/printing-package-details?' + url, '_blank');
  }

  searchPage(event: any) {
    if (event && event.rows) {
      this.pageSize = event.rows;
    }

    if (this.searchLaunchedOnce) {
      this.loading = true;
      this.setFilters();

      if (this.filters) {
        this.filters.page = event.page + 1;
        this.filters.pageSize = this.pageSize;

        this.packagingService.colisageGetAll(
            this.filters.page,
            this.filters.clientId,
            this.filters.pageSize,
            this.filters.count,
            this.filters.deliveryPeriod,
            this.filters.customers,
            this.filters.rackID,
            undefined,
            this.filters.sort,
            this.filters.sortBy,
            this.filters.orderID,
            this.filters.BL,
            undefined,
            this.filters.orderRef,
            undefined,
            undefined
            ).subscribe(packages => {
              const packagesWithTotal = <any> packages;
              this.packages = packagesWithTotal.entities;
              this.totalRecords = packagesWithTotal.total;
              this.loading = false;
              this.collapseAllRows();
        }, (err) => {
              this.loading = false;
              console.log(err);
        });
      }
    }
  }

  setFilters() {
    this.filters.clientId = this.session.connectedUser.clientId;
    this.filters.customers = undefined;

    if (this.selectedCustomerIds && this.selectedCustomerIds.length > 0 && !this.customerChecked) {

      this.selectedCustomerIds.forEach((c, i) => {
        const selected = this.selectedCustomerIds[i];

        if (selected) {
          if (this.filters.customers === undefined) {
            this.filters.customers = c.toString();
          } else {
            this.filters.customers += ',' + c;
          }
        }
      });
    } else if (this.customerChecked) {
      this.customers.forEach((c, i) => {
        const client = this.customers[i];
        if (this.filters.customers === undefined) {

            this.filters.customers = client.id.toString();
        } else {
            this.filters.customers += ',' + client.id;
        }
      });
    } else {
      this.filters.customers = this.session.connectedUser.clientId.toString();
    }
  }

  expandAllRows() {
    if (this.packages) {
      this.packages.forEach(
        (p , i) => {
          this.loading = true;
          this.packagingService.colisageGetAllLines(
            this.session.connectedUser ? this.session.connectedUser.clientId : undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            this.filters.customers,
            p.rackID.toString(),
            p.deliveryID.toString(),
            p.deliveryYear.toString(),
            undefined,
            undefined).subscribe(detail => {
              this.packages[i].colisageLines = detail;
              this.loading = false;
            }, (err) => {
              this.loading = false;
              console.log(err);
            });
            this.packagesDataTable.expandedRowKeys[p.deliveryID] = 1;
        }
      );
    }
  }

  collapseAllRows() {
    this.packagesDataTable.expandedRowKeys = {};
  }

  customSort(event: LazyLoadEvent) {
    if (!event.sortField) { return; }
    if (event.sortOrder === 1) {
      this.filters.sortBy = 'asc';
    } else {
      this.filters.sortBy = 'desc';
    }
    this.filters.sort = event.sortField;
    this.searchPage({page: 0});
  }
}
