import { LazyLoadEvent } from 'primeng/api';
import { DeliveryRacksReleaseDialogComponent } from '@app/shared/components/delivery-racks-release-dialog/delivery-racks-release-dialog.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DeliveryRack } from '@app/models/models';
import { DeliveryRackFilters } from '@app/models/filters/deliveryRackFilters';
import { DeliveryRackService } from '@app/services/deliveryRack.service';
import { ExportService } from '@app/services/export.service';
import { Session } from '@app/app.session';
import { Paginator } from 'primeng/primeng';

@Component({
  selector: 'app-delivery-racks-table',
  templateUrl: './delivery-racks-table.component.html',
  styleUrls: ['./delivery-racks-table.component.scss']
})
export class DeliveryRacksTableComponent implements OnInit {

  @Input() deliveryRacks: DeliveryRack[];
  @Input() loading = false;
  @Input() totalRecords: number;
  @Input() filters: DeliveryRackFilters;
  @Input() printingMode = false;

  public pageSize = 10;

  @ViewChild('releaseDialog') releaseDialog: DeliveryRacksReleaseDialogComponent;
  @ViewChild('paginator') paginator: Paginator;

  

  columns: any[];

  constructor(private translateService: TranslateService,
    private deliveryRackService: DeliveryRackService,
    private exportService: ExportService,
    private session: Session) {
    this.translateService.onLangChange.subscribe(this.onLangChange.bind(this));
    if (this.session && this.session.connectedUser && this.session.connectedUser.languageCode) {
      this.translateService.use(this.session.connectedUser.languageCode);
    }
  }

  onLangChange(langE: LangChangeEvent) {
    this.translate();
  }

  toogleLoad(value: boolean){
    this.loading = true;
  }

  translate() {
    this.columns =
      [
        { field: 'id', header: this.translateService.instant('DELIVERYRACKS_ID') },
        { field: 'customer.name', header: this.translateService.instant('DELIVERYRACKS_CUSTOMER') },
        { field: 'typeId', header: this.translateService.instant('DELIVERYRACKS_TYPE') },
        { field: 'type', header: this.translateService.instant('DELIVERYRACKS_DESCRIPTION') },
        { field: 'daysOut', header: this.translateService.instant('DELIVERYRACKS_WORK_DAYS') },
        { field: 'releaseDate', header: this.translateService.instant('DELIVERYRACKS_DEPOSIT_DATE') },
        { field: 'lastReleaseDate', header: this.translateService.instant('DELIVERYRACKS_RELEASE_DATE') },
        { field: 'address', header: this.translateService.instant('DELIVERYRACKS_ADDRESS') },
        { field: 'workSite', header: this.translateService.instant('DELIVERYRACKS_CONSTRUCTION_SITE') },
      ];
  }

  ngOnInit() {
    this.translate();

    this.releaseDialog.rackReleased.subscribe(() => { this.searchPage({ page: this.paginator.getPage() }); });
  }

  searchPage(event) {
    if (event && event.rows) {
      this.pageSize = event.rows;
    }

    this.filters.page = event.page + 1;
    this.filters.pageSize = this.pageSize;

    this.loading = true;
    this.deliveryRackService.deliveryRackGetAll(this.filters.page, this.filters.customerId, undefined, undefined,
       this.filters.rackID, this.filters.address, this.filters.worksite, this.filters.pageSize,
      true, this.filters.customers, this.filters.notreleased, this.filters.sort, this.filters.sortBy).subscribe(result => {
        this.deliveryRacks = result;
        if (result.length > 0) {
          this.totalRecords = result[0].totalRecords;
        }
        this.loading = false;
      }, (err) => {
        this.loading = false;
        console.log(err);
      });
  }

  releaseDeliveryRack(deliveryRack: DeliveryRack) {
    this.releaseDialog.show(deliveryRack);
  }

  exportExcel() {
    if (this.deliveryRacks && this.deliveryRacks.length > 0) {
      this.filters.pageSize = this.totalRecords;
      this.loading = true;
      this.deliveryRackService.deliveryRackGetAll(undefined, this.filters.customerId, undefined, undefined, this.filters.rackID, 
        this.filters.address, this.filters.worksite, this.filters.pageSize,
        true, this.filters.customers, this.filters.notreleased, this.filters.sort, this.filters.sortBy).subscribe(result => {
          const racks = <DeliveryRack[]>result;
          const racksToExport: any[] = [];
          racks.forEach(r => {
            const rack = {
              agres: r.id,
              customer: r.name,
              type: r.typeId,
              description: r.type,
              openingDays: r.daysOut,
              depositDate: r.releaseDate,
              releaseDate: r.lastReleaseDate,
              address: r.address,
              addressWork: r.workSite
            };
            racksToExport.push(rack);
          });

          const excelHeaders = [
            [
              this.translateService.instant('DELIVERYRACKS_ID'),
              this.translateService.instant('DELIVERYRACKS_CUSTOMER'),
              this.translateService.instant('DELIVERYRACKS_TYPE'),
              this.translateService.instant('DELIVERYRACKS_DESCRIPTION'),
              this.translateService.instant('DELIVERYRACKS_WORK_DAYS'),
              this.translateService.instant('DELIVERYRACKS_DEPOSIT_DATE'),
              this.translateService.instant('DELIVERYRACKS_RELEASE_DATE'),
              this.translateService.instant('DELIVERYRACKS_ADDRESS'),
              this.translateService.instant('DELIVERYRACKS_CONSTRUCTION_SITE')
            ]];

          this.exportService.exportToXlxs(excelHeaders, racksToExport, 'Sheet1', 'delivery_racks.xlsx');
          this.loading = false;
        }, (err) => {
          this.loading = false;
          console.log(err);
        });
    }
  }

  print() {
    if (this.deliveryRacks && this.deliveryRacks.length > 0) {
      let url = '';
      this.filters.pageSize = this.totalRecords; // Set page size to total entities number
      // TODO : Why? this.setFiltersCustomers();
      Object.keys(this.filters).forEach(prop => {
        if (this.filters[prop] !== null && this.filters[prop] !== undefined) {
          url += '&' + prop + '=' + this.filters[prop];
        }
      });
      window.open('#/printing/printing-delivery-racks?' + url, '_blank');
    }
  }

  customSort(event: LazyLoadEvent) {
    if (!event.sortField) { return; }
    if (event.sortField.includes('customer')) {
      this.filters.sort = 'customername';
    } else if (event.sortField.includes('addressLine1')) {
      this.filters.sort = 'address';
    } else if (event.sortField.includes('workSiteId')) {
      this.filters.sort = 'workSiteId';
    } else {
      this.filters.sort = event.sortField;
    }

    if (event.sortOrder === 1) {
      this.filters.sortBy = 'asc';
    } else {
      this.filters.sortBy = 'desc';
    }
    this.searchPage({ page: 0 });
  }

}
